<template>
	<BCard>
		<BCardHeader>
			<BCardTitle class="mx-auto mx-md-0 text-center">
				Solicitação de nº {{ processID }} foi realizada com Sucesso
			</BCardTitle>
		</BCardHeader>
		<BCardBody class="d-flex align-items-center flex-wrap">
			<SuccessCheck class="col-md-3 mx-auto mx-md-0 " />
			<BCardText class="text-center text-md-left col-md-9">
				<h4 class="mb-2 text-responsive">
					Pronto!
				</h4>
				<h4 class="text-responsive">
					O Termo de Adesão ao Programa de Roçada já está em seu e-mail para assinatura
					digital.
				</h4>
				<h4 class="text-responsive">
					Assim que finalizar a assinatura, é só relaxar que cuidaremos de tudo para você,
					seu lote estará roçado e em bom estado de conservação.
				</h4>
			</BCardText>
		</BCardBody>
		<BCardFooter class="d-flex justify-content-start justify-content-md-end">
			<BButton
				variant="primary"
				class="col-12 col-md-4 mx-auto"
				@click="$router.push('minhas-solicitacoes')"
			>
				Acompanhar Solicitações
			</BButton>
		</BCardFooter>
	</BCard>
</template>

<script>
import {
	BCard,
	BCardBody,
	BCardTitle,
	BCardHeader,
	BCardFooter,
	BButton,
	BCardText,
} from 'bootstrap-vue'
import SuccessCheck from '@/views/components/SucessCheck.vue'

export default {
	name: 'Success',
	props: {
		processID: {
			type: String,
			required: true,
		},
	},
	components: {
		BCard,
		BCardBody,
		BCardTitle,
		BCardHeader,
		BCardFooter,
		BButton,
		BCardText,
		SuccessCheck,
	},
}
</script>

<style>
.text-responsive {
	font-size: 1.2rem !important;
}
</style>
