<template>
	<div>
		<Success v-if="!result.hasErrors" :processID="result.processID" />
		<Failure v-if="result.hasErrors" :errorMessage="result.errorMessage" />
	</div>
</template>

<script>
import Success from '../components/Success.vue'
import Failure from '../components/Failure.vue'
export default {
	name: 'StepFour',
	props: {
		result: {
			type: Object,
			required: true,
		},
	},
	components: {
		Success,
		Failure,
	},
}
</script>
