<template>
	<div>
		<BCard>
			<BCardBody>
				<BCardText class="text-center text-md-left">
					<h3 class="mb-2">
						Solicitação já existente
					</h3>
					<div class="text-justify text-md-left">
						<h5>
							Uma solicitação de adesão ao Programa de Roçada em andamento foi encontrada
							para esse contrato.
						</h5>
						<h5>
							Clique em
							<router-link to="/minhas-solicitacoes">Minhas Solicitações</router-link>
							para ver o andamento ou selecione outro contrato.
						</h5>
					</div>
				</BCardText>
			</BCardBody>
			<BCardFooter class="d-flex justify-content-start justify-content-md-end">
				<BButton
					variant="primary"
					class="col-12 col-md-4 mx-auto"
					@click="$router.push('/minhas-solicitacoes')"
				>
					Acompanhar Solicitação
				</BButton>
			</BCardFooter>
		</BCard>
	</div>
</template>

<script>
import { BCard, BCardBody, BCardFooter, BButton, BCardText } from 'bootstrap-vue'
export default {
	name: 'StepOne',
	components: {
		BCard,
		BCardBody,
		BCardFooter,
		BButton,
		BCardText,
	},
}
</script>
