<template>
	<div>
		<SelectContract />
		<SelectContractLabel />
		<BCard>
			<BCardHeader>
				<BCardTitle class="text-center">
					Solicitar Adesão ao Programa de Roçada
				</BCardTitle>
			</BCardHeader>
			<BCardBody>
				<BCardText class="text-justify">
					<template
						v-if="
							selectedContract.situacao === 40 && selectedContract.empreendimento === 30
						"
					>
						<h5 class="font-weight-bold">O que é o Programa de Roçada?</h5>
						<p>
							É uma solução simples e barata que a São Bento encontrou para te ajudar a
							manter o seu lote sempre roçado.
						</p>
						<p>
							Por um investimento baixo, quase simbólico, nós deixamos seu terreno roçado
							o ano inteiro, assim você não precisa se preocupar com sujeira, bichos e
							eventuais multas aplicadas pelo município.
						</p>
						<h5 class="font-weight-bold">Qual o valor do Programa de Roçada?</h5>
						<p>
							Para clientes com financiamento em andamento, o valor é anual de
							<b>{{ formatPriceTable(selectedContract.taxa_limpeza_valor) }}</b
							>, pagos em uma única parcela, no momento da adesão e renovada anualmente.
							Ou seja, menos de <b>R$0,70</b> por dia.
						</p>
					</template>
					<template v-else-if="selectedContract.situacao === 40">
						<h5 class="font-weight-bold">O que é o Programa de Roçada?</h5>
						<p>
							É uma solução simples e barata que a São Bento encontrou para te ajudar a
							manter o seu lote sempre roçado.
						</p>
						<p>
							Por um investimento baixo, quase simbólico, nós deixamos seu terreno roçado
							o ano inteiro, assim você não precisa se preocupar com sujeira, bichos e
							eventuais multas aplicadas pelo município.
						</p>
						<h5 class="font-weight-bold">Qual o valor do Programa de Roçada?</h5>
						<p>
							Para clientes com financiamento em andamento, o valor é anual de
							<b> {{ formatPriceTable(selectedContract.taxa_limpeza_valor) }}</b
							>, mas esse total é parcelado em <b>12x de R$20,00</b> e incluso nos boletos
							da sua parcela, ou seja, menos de R$ 0,70 por dia. Sendo assim, os lotes com
							financiamento em aberto não tem opção de pagamento à vista, o valor será
							parcelado e incidirá nos próximos 12 boletos.
						</p>
					</template>
					<template v-else-if="selectedContract.situacao === 50">
						<h5 class="font-weight-bold">O que é o Programa de Roçada?</h5>
						<p>
							É uma solução simples e barata que a São Bento encontrou para te ajudar a
							manter o seu lote sempre roçado.
						</p>
						<p>
							Por um investimento baixo, quase simbólico, nós deixamos seu terreno roçado
							o ano inteiro, assim você não precisa se preocupar com sujeira, bichos e
							eventuais multas aplicadas pelo município.
						</p>
						<h5 class="font-weight-bold">Qual o valor do Programa de Roçada?</h5>
						<p>
							Para clientes com o lote quitado, o investimento é de
							<b>{{ formatPriceTable(selectedContract.taxa_limpeza_valor) }}</b>
							por ano, pagos em uma parcela única, no momento da adesão e renovada
							anualmente. Ou seja, menos de R$ 1,00 por dia.
						</p>
					</template>
				</BCardText>
			</BCardBody>
			<BCardFooter class="d-flex justify-content-start justify-content-md-end">
				<BButton variant="primary" class="col-12 col-md-4 mx-auto" @click="nextStep()">
					Quero Cadastrar
				</BButton>
			</BCardFooter>
		</BCard>
	</div>
</template>

<script>
import {
	BCard,
	BCardBody,
	BCardTitle,
	BCardHeader,
	BCardFooter,
	BButton,
	BCardText,
} from 'bootstrap-vue'
import store from '@/store'
import SelectContractLabel from '@/views/components/selectContractLabel.vue'
import SelectContract from '@/views/components/selectContract.vue'
import formatValue from '@/utils/formatValue'
export default {
	name: 'StepOne',
	components: {
		BCard,
		BCardBody,
		BCardTitle,
		BButton,
		BCardHeader,
		BCardFooter,
		BCardText,
		SelectContractLabel,
		SelectContract,
	},
	data() {
		return {
			formatValue,
		}
	},
	mixins: [formatValue],
	methods: {
		nextStep() {
			this.$emit('nextStep')
		},
	},
	computed: {
		selectedContract() {
			return store.state.contracts.selectedContract
		},
	},
}
</script>
