<template>
    <BCard>
        <BCardHeader>
            <BCardTitle class="mx-auto mx-md-0 text-center">
                Não foi possível realizar a solicitação.
            </BCardTitle>
        </BCardHeader>
        <BCardBody class="d-flex align-items-center flex-wrap">
            <FailureCheck class="col-md-3 mx-auto mx-md-0 " />
            <BCardText class="text-center text-md-left col-md-9">
                <h4 class="text-responsive">
                    {{ errorMessage }}
                </h4>
            </BCardText>
        </BCardBody>
        <BCardFooter
            class="d-flex justify-content-start justify-content-md-end"
        >
            <BButton
                variant="primary"
                class="col-12 col-md-4 mx-auto"
                @click="$router.push('/')"
            >
                Voltar
            </BButton>
        </BCardFooter>
    </BCard>
</template>

<script>
    import {
        BCard,
        BCardBody,
        BCardTitle,
        BCardHeader,
        BCardFooter,
        BButton,
        BCardText,
    } from "bootstrap-vue"
    import FailureCheck from "@/views/components/FailureCheck.vue"

    export default {
        name: "Success",
        props: {
            errorMessage: {
                type: String,
                show: true,
                required: true,
            },
        },
        components: {
            BCard,
            BCardBody,
            BCardTitle,
            BCardHeader,
            BCardFooter,
            BButton,
            BCardText,
            FailureCheck,
        },
    }
</script>

<style>
    .text-responsive {
        font-size: 1.2rem !important;
    }
</style>
