<template>
	<div>
		<SelectContractLabel />
		<BCard
			v-if="
				!processoEmAndamento &&
					!loading &&
					selectedContract.taxa_limpeza_contratado == 'Não'
			"
		>
			<BCardBody>
				<BCardText class="text-center text-md-left">
					<h4 class="mb-3 font-weight-bold">
						Requisitos necessários para adesão ao Programa de Roçada
					</h4>
					<div class="d-flex text-left my-1 align-items-center">
						<feather-icon
							:icon="returnIcon(naoPossuiObra).icon"
							size="19"
							:color="returnIcon(naoPossuiObra).color"
							class="mr-50"
						/>
						<h6 class="requisito">
							Estar com o lote sem marcações, sem muro, sem construção em andamento e sem
							construção concluída.
						</h6>
					</div>
					<div class="d-flex text-left my-1 align-items-center">
						<feather-icon
							:icon="returnIcon(quitadoOuEfetivado).icon"
							size="19"
							:color="returnIcon(quitadoOuEfetivado).color"
							class="mr-50"
						/>
						<h6 class="requisito">
							<template v-if="selectedContract.situacao === 50">
								Estar com o lote quitado.
							</template>
							<template v-else>
								Estar com o financiamento em dia.
							</template>
						</h6>
					</div>
					<div class="d-flex text-left my-1 align-items-center">
						<feather-icon
							:icon="returnIcon(liberadoLimpeza).icon"
							size="19"
							:color="returnIcon(liberadoLimpeza).color"
							class="mr-50"
						/>
						<h6 class="requisito">
							Empreendimento estar liberado para adesão ao Programa de Roçada.
						</h6>
					</div>
				</BCardText>
				<BFormCheckbox class="mt-4 text-justify" v-model="termoAceito">
					<h5>
						Estou ciente de que a primeira roçada em meu lote será realizada em até 90
						dias, a partir da assinatura do termo de adesão.
					</h5>
				</BFormCheckbox>
			</BCardBody>
			<BCardFooter class="d-flex justify-content-start justify-content-md-end">
				<BButton
					variant="primary"
					class="col-12 col-md-4 mx-auto"
					@click="handleSubmitProcess()"
					:disabled="!preencheRequisitos || !termoAceito"
				>
					<template v-if="!loading">
						Solicitar Programa de Roçada
					</template>
					<template v-else>
						<b-spinner small />
					</template>
				</BButton>
			</BCardFooter>
		</BCard>
		<ProcessoExistente v-else-if="processoEmAndamento && !loading" />
		<BCard v-else-if="selectedContract.taxa_limpeza_contratado == 'Sim' && !loading">
			<BCardBody>
				<BCardText class="text-center text-md-left">
					<h3 class="mb-2">
						O seu contrato possui o Programa de Roçada ativo, ele só será cancelado após a
						finalização do prazo de adesão. Em caso de dúvidas, entrar em contato com um
						atendente pelo chat.
					</h3>
					<div class="text-justify text-md-left">
						<h5>
							Clique em
							<router-link to="/minhas-solicitacoes">Minhas Solicitações</router-link>
							para ver o andamento ou selecione outro contrato.
						</h5>
					</div>
				</BCardText>
			</BCardBody>
			<BCardFooter class="d-flex justify-content-start justify-content-md-end">
				<BButton
					variant="primary"
					class="col-12 col-md-4 mx-auto"
					@click="$router.push('/minhas-solicitacoes')"
				>
					Minhas Solicitações
				</BButton>
			</BCardFooter>
		</BCard>
		<div class="d-flex justify-content-center" v-else-if="loading">
			<BSpinner />
		</div>
	</div>
</template>

<style scoped>
.requisito {
	margin: 0;
	width: 90%;
}
</style>

<script>
import SelectContractLabel from '@/views/components/selectContractLabel.vue'
import ProcessoExistente from '../components/ProcessoExistente.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import services from '@/services'
import formatValue from '@/utils/formatValue'
import store from '@/store'
import {
	BCard,
	BCardBody,
	BCardFooter,
	BButton,
	BCardText,
	BSpinner,
	BFormCheckbox,
} from 'bootstrap-vue'

export default {
	name: 'StepTwo',
	components: {
		SelectContractLabel,
		BCard,
		BCardBody,
		BCardFooter,
		BButton,
		BCardText,
		BSpinner,
		ProcessoExistente,
		BFormCheckbox,
	},

	mixins: [formatValue],

	data() {
		return {
			solicitacoes: [],
			termoAceito: false,
			loading: false,
			processoEmAndamento: false,
			errorMessage: null,
			hasErrors: false,
			processID: null,
		}
	},
	methods: {
		nextStep() {
			if (this.preencheRequisitos) {
				if (this.termoAceito) {
					this.$emit('submitProcess', {
						processID: this.processID,
						hasErrors: this.hasErrors,
						errorMessage: this.errorMessage,
					})
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Ops!',
							icon: 'XIcon',
							variant: 'danger',
							text: 'Para solicitar é necessário aceitar os termos e condições.',
						},
					})
				}
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Ops!',
						icon: 'XIcon',
						variant: 'danger',
						text:
							'Para demarcar seu lote seu contrato deve preencher todos os requisitos necessários.',
					},
				})
			}
		},
		async handleSubmitProcess() {
			this.loading = true

			const { data, error } = await services.process.solicitarProgramaRocada(
				this.selectedContract
			)

			if (data) {
				this.processID = data.iprocess
				this.loading = false
				this.nextStep()
			} else {
				this.errorMessage = error.message
				this.hasErrors = true
				this.loading = false
				this.nextStep()
			}
		},

		returnIcon(isAdimplente) {
			if (isAdimplente) {
				return {
					icon: 'CheckIcon',
					color: 'green',
				}
			} else {
				return {
					icon: 'XIcon',
					color: 'red',
				}
			}
		},

		async getSolicitacoes() {
			this.loading = true
			this.solicitacoes = await services.process.getProcesses(
				this.$apolloProvider.defaultClient
			)

			setTimeout(() => {
				this.loading = false
			}, 300)
		},

		processoExistente() {
			if (this.solicitacoes.length > 0) {
				this.solicitacoes.forEach(solicitacao => {
					if (
						solicitacao.id_processo == 'taxa_limpeza' &&
						solicitacao.is_finished != 1 &&
						solicitacao.is_canceled != 1
					) {
						this.processoEmAndamento = true
					}
				})
			} else {
				this.processoEmAndamento = false
			}
		},
	},

	computed: {
		selectedContract() {
			return store.state.contracts.selectedContract
		},

		taxaLimpezaContratado() {
			if (this.selectedContract.taxa_limpeza_contratado == 'Sim') {
				return true
			} else {
				return false
			}
		},

		liberadoLimpeza() {
			if (this.selectedContract.liberado_limpeza == 'Sim') {
				return true
			} else {
				return false
			}
		},

		naoPossuiObra() {
			if (this.selectedContract.status_obra == '') {
				return true
			} else {
				return false
			}
		},

		quitadoOuEfetivado() {
			if (this.selectedContract.situacao == 50) {
				return true
			} else if (this.selectedContract.parcelas_atrasadas <= 5) {
				return true
			} else {
				return false
			}
		},

		preencheRequisitos() {
			if (
				this.liberadoLimpeza &&
				this.naoPossuiObra &&
				this.quitadoOuEfetivado &&
				!this.taxaLimpezaContratado
			) {
				return true
			} else {
				return false
			}
		},
	},

	async mounted() {
		await this.getSolicitacoes()
		await this.processoExistente()
	},

	watch: {
		selectedContract: {
			handler: function() {
				this.getSolicitacoes()
				this.processoExistente()
			},
		},
	},
}
</script>
